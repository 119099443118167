// custom css


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// normalize CSS across browsers
import "./src/css/normalize.css";
import "./src/css/bootstrap-grid.min.css"
import "./src/css/global.css"
import "./src/css/font.css"

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    if (location.hash && location.hash.includes("#")) {
        return true;
    }
    window.history.scrollRestoration = 'manual';

    const currentPosition = getSavedScrollPosition(location, location.key);
    let timeout = 100;
    if (currentPosition && currentPosition.length > 1 && currentPosition[1]) {
        const y = currentPosition[1];
        if (y > (2 * window.innerHeight)) {
            timeout = 750;
        }
    }
    setTimeout(() => {
        window.scrollTo(...(currentPosition || [0, 0]));
    }, timeout);
    return false;
};

export const onInitialClientRender = () => {
    if (typeof window !== "undefined") {
      var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = "https://embed.tawk.to/67e3081d029d97190a735fd6/1in7dfcp1";
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      })();
    }
  };